
























































































import {
  ref,
  reactive,
  defineComponent,
} from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

export default defineComponent({
  components: {
    FadeTransition,
  },

  setup() {
    const state = reactive({})

    const selectedSlideIndex = ref(0)

    const showGallery = ref(false)

    function setSlideAndShowDialog(index: number) {
      selectedSlideIndex.value = index - 1
      showGallery.value = true
    }

    return {
      state,
      showGallery,
      selectedSlideIndex,
      setSlideAndShowDialog,
    }
  },
})

