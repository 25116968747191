





















































































import {
  ref,
  toRefs,
  computed,
  defineComponent,
  nextTick,
} from 'vue-demi'
// import { useIntersectionObserver } from '@vueuse/core'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { useStore } from '@/store'

import { ExternalConversationMessage, UnsentConversationMessage } from '@/types/api'
import { checkFileFormat, getFileLink } from '@/utils/files'

interface Props extends Record<string, any> {
  readonly message: ExternalConversationMessage | UnsentConversationMessage
}

export default defineComponent<Props>({
  components: {
    FadeTransition,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { message } = toRefs(props)
    const store = useStore()

    const target = ref(null)

    const showMenu = ref(false)

    const menuPosition = ref({ x: 0, y: 0 })

    // @ts-ignore
    const isCurrentUser = computed(() => store.state.Auth.id === message.value.user_id)

    const timeFromNTime = computed(() => {
      let date
      // @ts-ignore
      if (message.value.ntime) {
      // @ts-ignore
        date = new Date(+message.value.ntime * 1000)
      // @ts-ignore
      } else if (message.value.date) {
      // @ts-ignore
        date = new Date(+message.value.date)
      }
      if (date) {
        const minutesWithNilIfNeeded = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        const time = `${date.getHours()}:${minutesWithNilIfNeeded}`
        return time
      }
      return false
    })

    // const { stop } = useIntersectionObserver(
    //   target,
    //   ([{ isIntersecting }], observerElement) => {
    //   // @ts-ignore
    //     if (!isIntersecting || message.value.user_id === 0) { return }
    //     // @ts-ignore
    //     emit('mark-as-read', message.value.id)
    //     observerElement.disconnect()
    //   },
    //   { rootMargin: '10px' },
    // )

    function show(e: any) {
      e.preventDefault()
      showMenu.value = false
      menuPosition.value.x = e.clientX
      menuPosition.value.y = e.clientY
      nextTick(() => {
        showMenu.value = true
      })
    }

    return {
      target,
      isCurrentUser,
      timeFromNTime,
      show,
      showMenu,
      menuPosition,
      checkFileFormat,
      getFileLink,
    }
  },
})

