



































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue-demi'

import { Conversation } from '@/types/api'

import DialogPreviewListItem from '@/components/DialogPreviewListItem.vue'

interface Props extends Record<string, any> {
  readonly activeDialog: number
  readonly dialogs: Conversation[]
}

export default defineComponent<Props>({
  components: {
    DialogPreviewListItem,
  },

  props: {
    activeDialog: {
      type: Number,
      required: true,
    },
    dialogs: {
      type: Array,
      required: false,
      default: [],
    },
  },

  setup(props) {
    const computedHeight = computed(() => 100)
    const { dialogs, activeDialog } = toRefs(props)

    const conversationGroups = ref<any>([])
    const panels = ref<number[]>([])

    function generateConversationGroups() {
      conversationGroups.value = []
      if (dialogs.value.length === 0) {
        conversationGroups.value = []
      }
      dialogs.value.forEach((element: Conversation) => {
        const index = conversationGroups.value.findIndex((elem: {order_id: number; conversations: Conversation[]}) => elem.order_id === element.order_id)
        if (index === -1) {
          conversationGroups.value.push({ order_id: element.order_id, conversations: [element], order_title: element.order_title })
        } else {
          conversationGroups.value[index].conversations.push(element)
        }

        if (element.id === activeDialog.value) {
          panels.value.push(conversationGroups.value.findIndex((elem: {order_id: number; conversations: Conversation[]}) => elem.order_id === element.order_id))
        }
      })
    }

    onMounted(() => generateConversationGroups())

    watch(
      () => dialogs.value,
      () => generateConversationGroups(),
      { deep: true, immediate: true },
    )

    return {
      computedHeight,
      conversationGroups,
      panels,
    }
  },
})

