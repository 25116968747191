
































import { defineComponent } from 'vue-demi'

interface Props extends Record<string, any> {
  readonly withDivider: boolean
  readonly noGutters: boolean
  readonly noPadding: boolean
  readonly nested: boolean
}

export default defineComponent<Props>({
  props: {
    withDivider: {
      type: Boolean,
      default: false,
    },

    noGutters: {
      type: Boolean,
      default: false,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    nested: {
      type: Boolean,
      default: false,
    },
  },
})

