












































































import { defineComponent } from 'vue-demi'

export default defineComponent({
  props: {
    dialogInfo: {
      type: Object,
      required: true,
    },
    conversationList: {
      type: Array,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    isDialogsListVisible: {
      type: Boolean,
      required: true,
    },
  },
})
