
























































































import {
  ref,
  onMounted,
  defineComponent,
  inject,
  reactive,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { User } from '@/types/api'

import { usersRepository } from '@/repositories/users'

import WorkerCard from '@/components/WorkerCard.vue'
import CategoriesMenu from '@/components/CategoriesMenu.vue'

interface Props extends Record<string, any> {
  foo: string
}

interface Category {
  id?: number
  key: string
  title: string
}

const categories: Category[] = [
  { id: 0, key: 'all', title: 'все категории' },
  { id: 1, key: 'all', title: 'все категории' },
  { id: 2, key: 'all', title: 'все категории' },
  { id: 3, key: 'all', title: 'все категории' },
  { id: 4, key: 'all', title: 'все категории' },
]

export default defineComponent<Props>({
  props: {},

  components: {
    WorkerCard,
    CategoriesMenu,
    FadeTransition,
  },

  setup() {
    const toast = useToast()

    const state = reactive({
      menu: false,
      filters: {
        categories: [],
      },
    })

    const isNotTablet = inject<boolean>('isNotTablet')

    const workerList = ref<User[]>([])

    async function loadWorkers() {
      try {
        workerList.value = await usersRepository.findMany({}, {}, undefined, {})
      } catch (error) {
        toast.error(`Workers/loadWorkers: ${error.error}`)
      }
    }

    onMounted(loadWorkers)

    return {
      workerList,
      isNotTablet,
      categories,
      ...state,
    }
  },
})

