






















import {
  defineComponent,
} from 'vue-demi'

// @ts-ignore

import DialogMessageFile from '@/components/DialogMessageFile.vue'
import DialogMessagePhoto from '@/components/DialogMessagePhoto.vue'
import DialogMessageText from '@/components/DialogMessageText.vue'

import { ExternalConversationMessage, UnsentConversationMessage } from '@/types/api'

interface Props extends Record<string, any> {
  readonly message: ExternalConversationMessage | UnsentConversationMessage
}

export default defineComponent<Props>({
  components: {
    DialogMessagePhoto,
    DialogMessageText,
    DialogMessageFile,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },
})

