import {
  watch,
  computed,
  nextTick,
  Ref,
} from 'vue-demi'

import localforage from 'localforage'

import { Payload } from '@/types/store'
import { ACTIONS, GETTERS, MODULES } from '@/store/types'
import { useStore } from '@/store'

import { useToast } from 'vue-toastification/composition'
import { useI18n } from '@/i18n'

// @ts-ignore
import { v4 as uuidv4 } from 'uuid'

import isIntoView from '@/utils/isIntoView'

import {
  ConversationDateGroup, ConversationMessage, ConversationsParams,
} from '@/types/api'

// @ts-ignore
import useImagesCount from '@/composables/useImagesCount'

import { ConversationsProvider } from '@/providers'

import { checkImageFileFormat } from '@/utils/files'

const conversationsProvider = new ConversationsProvider()

export default function useMessageRetriever(dialogId: Ref<any>, messageView: Ref<HTMLDivElement | undefined>) {
  const store = useStore()

  const toast = useToast()

  const i18n = useI18n()

  const current_user_id = computed(() => store.state.Profile.user?.id)

  const messages = computed<ConversationDateGroup[]>(() => store.getters[`${MODULES.Conversations}/${GETTERS.Conversations.getMessagesListByConversation}`]({
    id: dialogId.value,
  }))

  const { currentImagesCount, currentFilesCount } = useImagesCount(messages)

  async function messagesScrollListener() {
    if (checkIfFetchNeeded()) {
      const messageWidget = document.querySelector('.dialog-messages-widget')
      const messageWidgetLast = messageWidget?.scrollHeight

      if (messageView && messageView.value) messageView.value.removeEventListener('scroll', messagesScrollListener)
      const lastNTime = +messages.value[0].messages[0].ntime
      await fetchMessages(dialogId.value, 'up', +messages.value[0].messages[0].ntime)

      nextTick(() => {
        if (messageView
              && messageView.value
              && messageWidget
              && messageWidgetLast
              && messageView?.value.scrollTop === 0
              && +messages.value[0].messages[0].ntime !== lastNTime) messageView.value.scrollTo(0, messageWidget?.scrollHeight - messageWidgetLast + currentImagesCount.value * 202 + currentFilesCount.value * 92)
      })

      if (!messages.value[0].messages[0].first_message) setOnScrollListener()
    }
  }

  async function onSubmitMessage(content: any) {
    // TODO: think how to perform old messages sending by one
    try {
      let serverResponse

      localforage.config({
        driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name: 'myApp',
        version: 1.0,
        storeName: 'messages', // Should be alphanumeric, with underscores.
        description: 'messages',
      })

      let previousMessage: any[] | null

      if (typeof content === 'string') {
        previousMessage = await localforage.getItem(`message[${dialogId.value}]::text`)
        const messageDate = Date.now()

        await localforage.setItem(`message[${dialogId.value}]::text`, previousMessage ? [...previousMessage, {
          content, prevUuid: uuidv4(), date: messageDate, kind: 'text', type: 'unsent', author_id: current_user_id.value,
        }] : [{
          content, prevUuid: uuidv4(), date: messageDate, kind: 'text', type: 'unsent', author_id: current_user_id.value,
        }])

        serverResponse = await conversationsProvider.sendMessage({ id: dialogId.value, type: 'string' }, { content })

        if (!serverResponse) {
          toast.error(i18n.t('ui.errors.cannot_send_message'))
          return false
        }
        await localforage.setItem(`message[${dialogId.value}]::text`, previousMessage)

        scrollMessageView(true)
        return true
      }
      const previousMessages: any[] | null = await localforage.getItem(`message[${dialogId.value}]::files`)

      previousMessage = previousMessages
      const fileArr: any = []

      content.forEach((elem: any) => {
        const messageDate = Date.now()
        let kind = 'file'
        const filename = elem.name || 'Без названия'
        const elemNameArray = elem.name.split('.')
        const file_format: string = elemNameArray[elemNameArray.length - 1]

        if (elem.name && ['image', 'png', 'jpeg', 'jpg', 'webp', 'gif', 'svg'].includes(file_format)) {
          kind = 'image'
        }
        fileArr.push({
          content: elem, prevUuid: uuidv4(), date: messageDate, kind, type: 'unsent', filename, file_format, author_id: current_user_id.value,
        })
      })

      await localforage.setItem(`message[${dialogId.value}]::files`, previousMessage ? [...previousMessage, ...fileArr] : [...fileArr])

      serverResponse = await conversationsProvider.sendMessage({ id: dialogId.value, type: 'file' }, content, { headers: { 'Content-Type': 'multipart/form-data' } })

      if (!serverResponse) {
        toast.error(i18n.t('ui.errors.cannot_send_message'))
      } else {
        await localforage.setItem(`message[${dialogId.value}]::files`, previousMessage)

        scrollMessageView(true)
        return true
      }
    } catch (e: any) {
      toast.error(i18n.t('ui.errors.on_message_send'))
    }
  }

  async function checkPreviousUnsentMessagesAndRetry(oneMessage?: any, deleteM?: boolean) {
    try {
      console.log(oneMessage)
      const previousTextMessages: any[] | null = await localforage.getItem(`message[${dialogId.value}]::text`)
      const previousFileMessages: any[] | null = await localforage.getItem(`message[${dialogId.value}]::files`)
      const newPreviousTextMessages: any = []
      const newPreviousFileMessages: any = []
      console.log(previousTextMessages, previousFileMessages, oneMessage, deleteM)
      if (previousTextMessages) {
        previousTextMessages.forEach(async element => {
          console.log(element)
          if (oneMessage && element.prevUuid !== oneMessage.message.prevUuid) {
            console.log(element)
            newPreviousTextMessages.push(element)
          } else if (oneMessage && !deleteM) {
            const prevServerResponse = await conversationsProvider.sendMessage({ id: dialogId.value, type: 'string' }, { content: element.content })
            console.log(element, prevServerResponse, oneMessage, deleteM)
            if (!prevServerResponse) {
              newPreviousTextMessages.push(element)
            }
          }
        })
        await localforage.setItem(`message[${dialogId.value}]::text`, newPreviousTextMessages)
        scrollMessageView(true)
      }

      if (previousFileMessages) {
        previousFileMessages.forEach(async element => {
          console.log(element)
          if (oneMessage && element.prevUuid !== oneMessage.prevUuid) {
            newPreviousFileMessages.push(element)
          } else if (oneMessage && !deleteM) {
            const prevServerResponse = await conversationsProvider.sendMessage({ id: dialogId.value, type: 'file' }, [element.content], { headers: { 'Content-Type': 'multipart/form-data' } })
            if (!prevServerResponse) {
              newPreviousFileMessages.push(element)
            }
          }
        })
        await localforage.setItem(`message[${dialogId.value}]::files`, newPreviousFileMessages)
        scrollMessageView(true)
      }
      return true
    } catch (e: any) {
      toast.error('ui.errors.on_previous_message_send')
      return false
    }
  }

  async function fetchMessages(id: ConversationMessage['id'], direction: string, lastNTime?: number | undefined) {
    return store.dispatch<Payload<ConversationsParams>>({
      type: `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationMessages}`,
      id,
      direction,
      lastNTime,
    })
  }

  async function fetchLastServerNTime(id: ConversationMessage['id']) {
    if (id) {
      return store.dispatch<Payload<ConversationsParams>>({
        type: `${MODULES.Conversations}/${ACTIONS.Conversations.fetchLastServerNTime}`,
        id,
      })
    }
    return false
  }

  function checkIfFetchNeeded() {
    if (
      (document.querySelectorAll('.message-item').length >= 6 && isIntoView(document.querySelectorAll('.message-item')[5]))
        || (document.querySelectorAll('.message-item').length >= 5 && isIntoView(document.querySelectorAll('.message-item')[4]))
        || (document.querySelectorAll('.message-item').length >= 4 && isIntoView(document.querySelectorAll('.message-item')[3]))
        || (document.querySelectorAll('.message-item').length >= 3 && isIntoView(document.querySelectorAll('.message-item')[2]))
        || (document.querySelectorAll('.message-item').length >= 2 && isIntoView(document.querySelectorAll('.message-item')[1]))
        || (document.querySelectorAll('.message-item').length >= 1 && isIntoView(document.querySelectorAll('.message-item')[0]))
    ) return true
    return false
  }

  function scrollMessageView(isToBottom = false) {
    if (messageView) {
      messageView.value?.scrollTo(0, messageView.value.scrollHeight + 2000)
      if (!isToBottom) setOnScrollListener()
    }
  }

  function setOnScrollListener() {
    document.querySelector('.message-view')?.addEventListener('scroll', messagesScrollListener)
  }

  async function setLastConversationMessageIfUnsent(id: ConversationMessage['id'], last_message: any) {
    return store.dispatch<Payload<ConversationsParams>>({
      type: `${MODULES.Conversations}/${ACTIONS.Conversations.setLastConversationMessageIfUnsent}`,
      id,
      last_message,
    })
  }

  const delay = 30000

  let messagesLongPoolingTimer = setTimeout(async function request() {
    await fetchMessages(dialogId.value, 'down')

    messagesLongPoolingTimer = setTimeout(request, delay)
  }, delay)

  watch(
    () => dialogId.value,
    async () => {
      await fetchMessages(dialogId.value, 'up')
      scrollMessageView()
    },
    { immediate: true },
  )
  watch(
    () => dialogId.value,
    fetchLastServerNTime,
    { immediate: true },
  )

  return {
    messages,
    onSubmitMessage,
    messagesLongPoolingTimer,
    checkPreviousUnsentMessagesAndRetry,
    scrollMessageView,
    checkImageFileFormat,
    setLastConversationMessageIfUnsent,
  }
}
