/* eslint-disable import/prefer-default-export */
import {
  CreateReviewParams,
  Order,
  Review,
} from '@/types/api'

import { ReviewsProvider } from '@/providers/reviews'
import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class ReviewsRepository implements DefaultCrudRepository<
Review,
any,
any,
any,
any,
any,
any
> {
  private provider: ReviewsProvider;

  public constructor() {
    this.provider = new ReviewsProvider()
  }

  public create(data: CreateReviewParams): Promise<Review> {
    return this.provider.create(data)
  }

  public async createMany(data: CreateReviewParams[]): Promise<Review[]> {
    const requests = data.map(review => this.create(review))

    const response = await Promise.allSettled(requests)

    return response.reduce<Review[]>((accumulator, request) => {
      if (request.status === 'fulfilled') { accumulator.push(request.value) }
      return accumulator
    }, [])
  }

  // eslint-disable-next-line
  public save(data: any): Promise<Order> {
    //   this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line
  public find(where: { id: number | string }) {
    //   return this.provider.getOne({ id: where.id })
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findMany(id: string, where: any, work_id?: number | string, search?: any, options?: EntityWhereManyInputOptions) {
    const { results } = await this.provider.getList(id, where)
    return results
  }

  // eslint-disable-next-line
  public update(where: any, data?: any): Promise<Review> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line
  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line
  public delete(where: any): Promise<Order> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line
  public deleteMany(where: any): Promise<{ count: number }> {
    throw new Error('Method not implemented.')
  }
  // eslint-disable-next-line
  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    throw new Error('Method not implemented.')
  }
  // eslint-disable-next-line
  public isExist(where: any): Promise<boolean> {
    throw new Error('Method not implemented.')
  }
}

export const reviewsRepository = new ReviewsRepository()
