





























































































import { defineComponent, toRefs } from 'vue-demi'

import Dialog from '@/components/views/Dialog.vue'
import DialogsList from '@/components/DialogsList.vue'
import TwoColumn from '@/components/layout/TwoColumn.vue'

import { useI18n } from '@/i18n'

export default defineComponent({
  props: {
    dialogId: {
      type: Number,
      required: true,
    },
    conversationList: {
      type: Array,
      required: true,
    },
    isDialogsListVisible: {
      type: Boolean,
      required: true,
    },
    searchMobile: {
      type: String,
      required: true,
    },
  },

  components: {
    Dialog,
    DialogsList,
    TwoColumn,
  },

  setup(props, { emit }) {
    const i18n = useI18n()
    const currentSearch = toRefs(props).searchMobile
    const currentIsDialogsListVisible = toRefs(props).isDialogsListVisible

    function updateDialogInfo(payload: any) {
      emit('update-dialog-info', payload)
    }

    function updatePropSearchMobile(payload: any) {
      emit('update:searchMobile', payload)
    }

    function onInput(val: any) {
      console.log(val)
      updatePropSearchMobile(val)
      emit('on-input-search', 'mobile')
    }
    return {
      i18n,
      currentSearch,
      currentIsDialogsListVisible,

      onInput,
      updateDialogInfo,
    }
  },
})
