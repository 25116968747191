





































import { defineComponent } from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { ExternalConversationMessage } from '@/types/api'

import DialogMessage from '@/components/DialogMessage.vue'

interface Props extends Record<string, any> {
  readonly messages: ExternalConversationMessage[]
}

export default defineComponent<Props>({
  components: {
    FadeTransition,
    DialogMessage,
  },

  props: {
    messages: {
      type: Array,
      required: true,
    },
    unsentMessages: {
      type: Array,
      required: false,
    },
  },

})

