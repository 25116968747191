




































































































































import {
  ref,
  watchEffect,
  defineComponent,
} from 'vue-demi'

import { onStartTyping, useMagicKeys } from '@vueuse/core'

import { rules, validateForm } from '@/utils/validators'
// import { useToast } from 'vue-toastification/composition'

import DragAndDropZone from '@/components/widgets/DragAndDropZone.vue'

import { VEmojiPicker } from 'v-emoji-picker'

const { ControlLeft, Enter, NumpadEnter /* keyCode's you want to monitor */ } = useMagicKeys()

export default defineComponent({
  components: {
    DragAndDropZone,
    VEmojiPicker,
  },
  setup(_, { emit }) {
    const form = ref<never>()

    // const toast = useToast()

    const submitBtn = ref()

    const docForm = ref<never>()

    const textField = ref<any>()

    const ruleForTextarea = [(v: any) => {
      if (v.length <= 2000) {
        if (textField.value) {
          document.querySelector('.dialog-input-widget .v-input__slot fieldset')?.classList.remove('text-form__error')
          submitBtn.value.$el.disabled = false
          submitBtn.value.$el.classList.remove('disabled-button')
        }

        return true
      }
      if (textField.value) {
          document.querySelector('.dialog-input-widget .v-input__slot fieldset')?.classList.add('text-form__error')
          submitBtn.value.$el.disabled = true
          submitBtn.value.$el.classList.add('disabled-button')
      }

      return false
    }]

    const message = ref<string>('')

    const documents = ref<any>([])

    const isDocumentPopupVisible = ref<boolean>(false)

    const showPicker = ref(false)

    onStartTyping(() => {
      if (!textField.value) { return }
      textField.value.focus()
    })

    watchEffect(() => {
      if (ControlLeft.value && (Enter.value || NumpadEnter.value)) {
        onSubmit('form')
      }
    })

    const emojiPickerLocale = {
      search: 'Поиск...',
      categories: {
        Activity: 'Действия',
        Flags: 'Флаги',
        Foods: 'Еда',
        Frequently: 'Недавние',
        Objects: 'Объекты',
        Nature: 'Природа',
        Peoples: 'Люди',
        Symbols: 'Символы',
        Places: 'Места',
      },
    }

    function onSubmit(formType: string) {
      if (formType === 'form') {
        const isValid = validateForm(form.value)

        if (!isValid) { return }

        // @ts-ignore
        if (!form.value.inputs[0].value || !form.value.inputs[0].value.trim()) { return }
        emit('submit-message', message.value.trim())

        message.value = ''
      } else {
        const isValid = validateForm(docForm.value)
        if (!isValid) { return }

        if (!documents.value) { return }

        emit('submit-message', documents.value)

        documents.value = []

        isDocumentPopupVisible.value = false
      }
    }

    function showDocumentsPopup() {
      isDocumentPopupVisible.value = !isDocumentPopupVisible.value
    }

    function onEmojiPick(emoji: any) {
      console.log(emoji)
      message.value += emoji.data
    }

    function computeIsDocumentPopupVisible() {
      if (isDocumentPopupVisible.value) {
        documents.value = []
        isDocumentPopupVisible.value = false
      } else {
        isDocumentPopupVisible.value = true
      }
    }

    return {
      form,
      docForm,
      rules,
      message,
      textField,
      isDocumentPopupVisible,
      showDocumentsPopup,
      onSubmit,
      documents,
      ruleForTextarea,
      showPicker,
      onEmojiPick,
      submitBtn,
      computeIsDocumentPopupVisible,
      emojiPickerLocale,
    }
  },
})

