
































import {
  ref,
  toRefs,
  defineComponent,
  onUnmounted,
  onMounted,
  onUpdated,
  watch,
} from 'vue-demi'

import useMessageRetriever from '@/composables/useMessageRetriever'

import DialogInputWidget from '@/components/widgets/DialogInputWidget.vue'
import DialogMessagesWidget from '@/components/widgets/DialogMessagesWidget.vue'

import localforage from 'localforage'
import { useI18n } from '@/i18n'

interface Props extends Record<string, any> {
  readonly dialogId: number
}

export default defineComponent<Props>({
  components: {
    DialogInputWidget,
    DialogMessagesWidget,
  },

  props: {
    dialogId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { dialogId } = toRefs(props)

    const messageView = ref<HTMLDivElement>()

    const i18n = useI18n()

    const {
      messages,
      onSubmitMessage,
      messagesLongPoolingTimer,
      checkPreviousUnsentMessagesAndRetry,
      setLastConversationMessageIfUnsent,
    } = useMessageRetriever(dialogId, messageView)

    const unsentMessages = ref()

    onMounted(async () => {
      const prevF: any = await localforage.getItem(`message[${dialogId.value}]::files`)
      const prevT: any = await localforage.getItem(`message[${dialogId.value}]::text`)
      if (prevF && prevT) {
        unsentMessages.value = [...prevF, ...prevT]
      } else if (prevF) unsentMessages.value = prevF
      else unsentMessages.value = prevT
    })

    onUpdated(async () => {
      const prevF: any = await localforage.getItem(`message[${dialogId.value}]::files`)
      const prevT: any = await localforage.getItem(`message[${dialogId.value}]::text`)
      if (prevF && prevT) {
        unsentMessages.value = [...prevF, ...prevT]
      } else if (prevF) unsentMessages.value = prevF
      else unsentMessages.value = prevT
    })

    onUnmounted(() => clearTimeout(messagesLongPoolingTimer))

    watch(
      () => unsentMessages.value,
      async () => {
        if (unsentMessages.value && unsentMessages.value.length) {
          const lastUnsentMessage = unsentMessages.value[unsentMessages.value.length - 1]
          const last_message = {
            author_id: lastUnsentMessage.author_id,
            content: (lastUnsentMessage.kind === 'file' || lastUnsentMessage.kind === 'image') ? 'Вложение' : lastUnsentMessage.content,
            date: lastUnsentMessage.date,
          }
          await setLastConversationMessageIfUnsent(dialogId.value, last_message)
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    return {
      messageView,
      messages,
      onSubmitMessage,
      unsentMessages,
      checkPreviousUnsentMessagesAndRetry,
      i18n,
    }
  },
})

