import {
  ref,
  watch,
  computed,
  Ref,
} from 'vue-demi'

import { ConversationDateGroup } from '@/types/api'

export default function useImagesCount(messages: Ref<ConversationDateGroup[]>) {
  const allImagesCount = computed(() => messages.value.reduce((accumulator, current) => accumulator + current.messages.filter(elem => {
    if (elem.kind === 'image') {
      return true
    }
    return false
  }).length, 0))

  const currentImagesCount = ref(0)

  const allFilesCount = computed(() => messages.value.reduce((accumulator, current) => accumulator + current.messages.filter(elem => {
    if (elem.kind === 'file') {
      return true
    }
    return false
  }).length, 0))

  const currentFilesCount = ref(0)

  watch(
    () => allImagesCount.value,
    (old, newVal) => {
      currentImagesCount.value = old - newVal
    },
  )

  watch(
    () => allFilesCount.value,
    (old, newVal) => {
      currentFilesCount.value = old - newVal
    },
  )

  return {
    currentFilesCount,
    currentImagesCount,
  }
}
