































import {
  ref,
  toRefs,
  computed,
  defineComponent,
  onBeforeUnmount,
} from 'vue-demi'
import { useIntersectionObserver } from '@vueuse/core'

import { Conversation, User } from '@/types/api'

import {
  usersRepository,
} from '@/repositories'
import { useStore } from '@/store'
import { GETTERS, MODULES } from '@/store/types'

interface Props extends Record<string, any> {
  readonly active: boolean
  readonly conversation: Conversation
}

export default defineComponent<Props>({
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    conversation: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { conversation } = toRefs(props)

    const target = ref<HTMLDivElement>()

    const users = ref<User[]>([])

    const userNames = computed(() => {
      if (!users.value.length) { return '' }

      if (users.value.length === 1) {
        const [user] = users.value
        return `${user.first_name} ${user.last_name}`
      }
      const accumulatedUsers = users.value.reduce<string>((accumulator, { first_name }) => {
        accumulator += `${first_name}, `
        return accumulator
      }, '')
      return accumulatedUsers.slice(0, accumulatedUsers.length - 2)
    })

    const store = useStore()

    const lastConversationMessage = computed(() => store.getters[`${MODULES.Conversations}/${GETTERS.Conversations.getConversationLastMessage}`](conversation.value.id))

    const { stop } = useIntersectionObserver(
      target,
      fetchDialogMeta,
      { rootMargin: '10px' },
    )

    onBeforeUnmount(stop)

    const isCurrentUser = computed(() => store.state.Auth.id === lastConversationMessage.value.author_id)

    async function fetchUsers(ids: number[]) {
      users.value = await Promise.all(ids.map(id => usersRepository.find({ id })))
    }

    function fetchDialogMeta() {
      const ids = conversation.value.user_ids.length ? [...conversation.value.user_ids] : [conversation.value.last_message.author_id]

      fetchUsers(ids)
    }

    function emitDialogInfo() {
      const payload = {
        avatars: users.value.map(user => user.id.toString()),
        userNames: userNames.value,
        company_title: conversation.value.company_title,
      }

      console.log(payload)

      emit('update-dialog-info', payload)
    }

    function getUser(userId: User['id']) {
      return users.value.find(user => user.id === userId)
    }

    return {
      target,
      emitDialogInfo,

      lastConversationMessage,
      users,
      isCurrentUser,

      getUser,
      userNames,
    }
  },
})

