






















































































































import {
  ref,
  toRefs,
  computed,
  defineComponent,
  nextTick,
} from 'vue-demi'
// import { useIntersectionObserver } from '@vueuse/core'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { useStore } from '@/store'

import { ExternalConversationMessage, UnsentConversationMessage } from '@/types/api'

import { checkImageFileFormat } from '@/utils/files'

interface Props extends Record<string, any> {
  readonly message: ExternalConversationMessage | UnsentConversationMessage
}

export default defineComponent<Props>({
  components: {
    FadeTransition,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const isImagePopupVisible = ref(false)

    const { message } = toRefs(props)
    const store = useStore()

    const target = ref(null)

    const showMenu = ref(false)

    const menuPosition = ref({ x: 0, y: 0 })

    const sourceForImg = ref('')
    // @ts-ignore
    if (message.value.content && message.value.kind === 'image') getImage(message.value.content)
    // @ts-ignore
    const isCurrentUser = computed(() => store.state.Auth.id === message.value.user_id)

    const timeFromNTime = computed(() => {
      let date
      // @ts-ignore
      if (message.value.ntime) {
      // @ts-ignore
        date = new Date(+message.value.ntime * 1000)
      // @ts-ignore
      } else if (message.value.date) {
      // @ts-ignore
        date = new Date(+message.value.date)
      }
      if (date) {
        const minutesWithNilIfNeeded = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        const time = `${date.getHours()}:${minutesWithNilIfNeeded}`
        return time
      }
      return false
    })

    // const { stop } = useIntersectionObserver(
    //   target,
    //   ([{ isIntersecting }], observerElement) => {
    //   // @ts-ignore
    //     if (!isIntersecting || message.value.user_id === 0) { return }
    //     // @ts-ignore
    //     emit('mark-as-read', message.value.id)
    //     observerElement.disconnect()
    //   },
    //   { rootMargin: '10px' },
    // )

    function show(e: any) {
      e.preventDefault()
      showMenu.value = false
      menuPosition.value.x = e.clientX
      menuPosition.value.y = e.clientY
      nextTick(() => {
        showMenu.value = true
      })
    }

    function getImage(img_link: string) {
      setTimeout(() => {
        if (typeof img_link === 'string') {
          const img = new Image()
          img.src = img_link
          img.onload = () => {
            sourceForImg.value = img_link
          }
          img.onerror = () => {
            getImage(img_link)
          }
          return true
        }
        // @ts-ignore
        toBase64(img_link).then(link => sourceForImg.value = link)
      }, 1000)
    }

    function toBase64(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }

    return {
      target,
      isCurrentUser,
      timeFromNTime,
      sourceForImg,
      isImagePopupVisible,
      show,
      showMenu,
      menuPosition,
      checkImageFileFormat,
    }
  },
})

