
























































import { defineComponent } from 'vue-demi'

import WorkTypes from '@/components/WorkTypes.vue'

export default defineComponent({
  components: {
    WorkTypes,
  },
})

