







































































import {
  ref,
  toRefs,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { VForm } from '@/types/vuetify'

// Localization
import { useI18n } from '@/i18n'

import { rules, unValidateForm, validateForm } from '@/utils/validators'

import { reviewsRepository } from '@/repositories/reviews'

interface Props extends Record<string, any> {
  readonly orderId: number | null
}

export default defineComponent<Props>({
  props: {
    orderId: {
      type: Number,
      required: true,
      default: () => -1,
    },
    ratingModal: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { orderId } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()

    const formRef = ref<VForm>()

    const review = ref({ rate: 1, text: '' })

    function setRating(newRating: number) {
      review.value.rate = newRating
    }

    function closeDialog() {
      emit('close-dialog')
    }

    const loading = ref(false)

    const errors = ref({})

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      try {
        loading.value = true

        const response = await reviewsRepository.create({ review: { ...review.value, order_id: orderId.value } })
        console.log(response)
        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        loading.value = false
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.review_sent'))

      resetForm()

      emit('close-dialog')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))
    }

    function resetForm() {
      unValidateForm(formRef.value)
      review.value = { rate: 1, text: '' }
    }

    return {
      loading,
      formRef,

      review,

      rules,
      onSubmit,
      closeDialog,
      setRating,
    }
  },
})

