


























import { defineComponent } from 'vue-demi'

import Dialog from '@/components/views/Dialog.vue'
import DialogsList from '@/components/DialogsList.vue'
import TwoColumn from '@/components/layout/TwoColumn.vue'

export default defineComponent({
  props: {
    dialogId: {
      type: Number,
      required: true,
    },
    conversationList: {
      type: Array,
      required: true,
    },
  },

  components: {
    Dialog,
    DialogsList,
    TwoColumn,
  },

  setup(props, { emit }) {
    function updateDialogInfo(payload: any) {
      emit('update-dialog-info', payload)
    }
    return {
      updateDialogInfo,
    }
  },
})
