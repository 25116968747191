

















import {
  defineComponent, inject, ref, watch,
} from 'vue-demi'

import ReviewCard from '@/components/ReviewCard.vue'
import { useStore } from '@/store'
import { ACTIONS, MODULES } from '@/store/types'
import { Payload } from '@/types/store'

export default defineComponent({
  components: {
    ReviewCard,
  },

  props: {
  },

  setup() {
    const store = useStore()
    const user: any = inject('user')

    const reviews = ref([])

    async function getReviewsList(id: string) {
      return store.dispatch<Payload<any>>({
        type: `${MODULES.Reviews}/${ACTIONS.Reviews.fetchReviewsList}`,
        id,
      })
    }

    watch(
      user,
      async () => {
        if (user.value) {
          const res = await getReviewsList(user.value.company_id)
          reviews.value = res[0].reviews.results
        }
      },
      { deep: true, immediate: true },
    )

    return { reviews }
  },
})

