var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"flat":"","shaped":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"center","align-content":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2"}},[_c('button',{staticClass:"mt-1",on:{"click":function($event){return _vm.$emit('update:isDialogsListVisible', !_vm.isDialogsListVisible)}}},[_c('img',{attrs:{"src":require("../assets/dialogs_list_icon.svg"),"alt":"Диалоги"}})])]),(_vm.dialogInfo.company_title)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"md":"5","cols":"8"}},[_c('span',{staticClass:"dialogs__title"},[_vm._v(_vm._s(_vm.dialogInfo.company_title))])]):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2"}},[(_vm.conversationList.length)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3508115531)},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.menu),function(ref,i){
var icon = ref.icon;
var title = ref.title;
var action = ref.action;
return _c('v-list-item',{key:i,staticStyle:{"cursor":"pointer"},on:{"click":function () { return action(); }}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)}),1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }