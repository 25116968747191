






















































import {
  ref,
  toRefs,
  computed,
  onMounted,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { useI18n } from '@/i18n'
import { useStore } from '@/store'

import { useVuetify } from '@/plugins/vuetify'
import { OrdersRepository } from '@/repositories'

import TwoColumn from '@/components/layout/TwoColumn.vue'
import TheDialogsDesktop from '@/components/TheDialogsDesktop.vue'
import TheDialogsMobile from '@/components/TheDialogsMobile.vue'
import TheDialogsToolbarDesktop from '@/components/TheDialogsToolbarDesktop.vue'
import TheDialogsToolbarMobile from '@/components/TheDialogsToolbarMobile.vue'

import Dialog from '@/components/views/Dialog.vue'
import DialogsList from '@/components/DialogsList.vue'
import CreateReviewDialog from '@/components/dialogs/CreateReviewDialog.vue'
import { ACTIONS, GETTERS, MODULES } from '@/store/types'
import { Payload } from '@/types/store'

const ordersRepository = new OrdersRepository()

const items = ['Foo', 'Bar', 'Fizz', 'Buzz']

interface DialogInfo {
  avatars: string[]
  userNames: string
  company_title: string
}

interface Props extends Record<string, any> {
  readonly id: string
}

export default defineComponent<Props>({
  components: {
    Dialog,
    TwoColumn,
    DialogsList,
    CreateReviewDialog,
    TheDialogsDesktop,
    TheDialogsMobile,
    TheDialogsToolbarDesktop,
    TheDialogsToolbarMobile,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { id } = toRefs(props)

    const vuetify = useVuetify()
    const toast = useToast()
    const i18n = useI18n()

    const store = useStore()

    const computedHeight = computed(() => vuetify.application.top + (24 * 2))

    const dialogId = computed(() => Number(id.value))
    const selectedDialogId = ref(0)
    const orderId = computed(() => store.getters[`${MODULES.Conversations}/${GETTERS.Conversations.getOrderIdByConversationId}`](dialogId.value))
    const dialogInfo = ref({})
    const search = ref('')
    const searchMobile = ref('')

    const conversationList = ref(store.state.Conversations.topics.conversations)

    const isDialogsListVisible = ref(false)
    const isControlsDialogVisible = ref(false)
    console.log(dialogId)

    const menu = [
      {
        icon: 'mdi-check-circle-outline',
        title: 'Заказ выполнен',
        action: () => completeOrder(),
      },
      {
        icon: 'mdi-check-circle-outline',
        title: 'Подтвердить выполнение',
        action: () => closeOrder(),
      },
      {
        icon: 'mdi-close-circle-outline',
        title: 'Отменить заказ',
        action: () => cancelOrder(),
      },
    ]

    const ratingModal = ref({
      modal: false,
    })

    async function cancelOrder() {
      await updateOrder('cancel')
    }

    async function completeOrder() {
      await updateOrder('finish')
    }

    async function closeOrder() {
      await updateOrder('close')
    }

    function triggerRatingDialog() {
      ratingModal.value.modal = true
    }

    function closeModal() {
      console.log('emited')
      ratingModal.value.modal = false
    }

    async function updateOrder(status: string) {
      const order = await ordersRepository.find({ id: orderId.value })
      const newOrder = { order: { event: status } }

      ordersRepository.update({ id: order.id }, { order: newOrder })
        .then(() => {
          if (status === 'finish') triggerRatingDialog()
        })
        .catch(err => err.forEach((val: string) => {
          toast.error(i18n.t(val))
        }))
    }

    async function loadConversationsWithSearchInput(val: string) {
      conversationList.value = await store.dispatch<Payload<any>>({
        type: `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationsList}`,
        search: val === 'mobile' ? searchMobile.value : search.value,
      })
    }

    async function onInput(val: 'string') {
      await loadConversationsWithSearchInput(val)
    }

    onMounted(() => loadConversationsWithSearchInput('mobile'))

    function updateDialogInfo(payload: DialogInfo) {
      dialogInfo.value = payload
      isDialogsListVisible.value = false
    }

    return {
      menu,
      items,
      dialogId,
      dialogInfo,
      computedHeight,
      conversationList,
      selectedDialogId,
      isDialogsListVisible,
      isControlsDialogVisible,
      i18n,
      orderId,
      ratingModal,
      search,
      searchMobile,

      updateDialogInfo,
      closeModal,
      loadConversationsWithSearchInput,
      onInput,
    }
  },
})

