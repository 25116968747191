



































































































import {
  ref,
  toRefs,
  computed,
  defineComponent,
  provide,
} from 'vue-demi'

import { User } from '@/types/api'

import { useI18n } from '@/i18n'
import { useRouter } from '@/router'

import Works from '@/components/views/WorkerWorks.vue'
import Reviews from '@/components/views/WorkerReviews.vue'
import Overview from '@/components/views/WorkerOverview.vue'
import Certification from '@/components/views/WorkerCertification.vue'

import Profile from '@/components/views/WorkerProfile.vue'
import { useLoadUserHook } from '@/shared/hooks'

interface Props extends Record<string, any> {
  readonly id: number | string
  readonly section: string
}

export default defineComponent<Props>({
  components: {
    works: Works,
    reviews: Reviews,
    overview: Overview,
    certification: Certification,
    Profile,
  },

  props: {
    id: {
      required: true,
    },

    section: {
      type: String,
      default: 'overview',
    },
  },

  setup(props) {
    const { id, section } = toRefs(props)

    const i18n = useI18n()
    const router = useRouter()

    // Headers
    const items = [
      { title: i18n.t('ui.me.overview'), component: 'overview' },
      { title: i18n.t('ui.me.certification'), component: 'certification' },
      { title: i18n.t('ui.me.works'), component: 'works' },
      { title: i18n.t('ui.me.reviews'), component: 'reviews' },
    ]

    const tab = computed({
      get: () => {
        let index = 0

        const itemIndex = items.findIndex(item => item.component === section.value)
        if (itemIndex !== -1) { index = itemIndex }

        return index
      },

      set: index => {
        router.push({ params: { section: items[index].component } })
      },
    })

    const user = ref<User>()

    provide('user', user)

    useLoadUserHook(Number(id.value), user)

    return {
      tab,
      user,
      items,
    }
  },
})

