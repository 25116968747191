var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"flat":"","shaped":""}},[_c('TwoColumn',{staticClass:"fill-height",attrs:{"nested":"","no-padding":"","no-gutters":"","with-divider":""},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('v-row',{staticClass:"py-2 dialogs__controls mt-0 align-center justify-space-between"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-2"},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)],1)]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('div',{staticClass:"col-9 pa-0 ma-0 mr-2 ml-n2 dialogs__search-group"},[_c('input',{staticClass:"dialogs__search-input",attrs:{"type":"text","placeholder":"Поиск"},domProps:{"value":_vm.currentSearch},on:{"input":function($event){return _vm.onInput($event.target.value)}}}),_c('v-icon',{staticClass:"dialogs__search-icon",class:{'pa-1': !_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-magnify ")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-row',{attrs:{"align":"center","align-content":"center"}},[(_vm.dialogInfo.company_title)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6"}},[_c('span',{staticClass:"dialogs__title"},[_vm._v(_vm._s(_vm.dialogInfo.company_title))])]):_vm._e(),_c('v-spacer'),(_vm.orderId)?_c('v-col',{attrs:{"cols":"1"}},[(_vm.conversationList.length)?_c('v-menu',{attrs:{"bottom":"","left":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1139071059)},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.menu),function(ref,i){
var icon = ref.icon;
var title = ref.title;
var action = ref.action;
return _c('v-list-item',{key:i,staticStyle:{"cursor":"pointer"},on:{"click":function () { return action(); }}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)}),1)],1):_vm._e()],1):_vm._e()],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }