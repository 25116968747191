























































































































import { defineComponent, toRefs } from 'vue-demi'

import TwoColumn from '@/components/layout/TwoColumn.vue'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    dialogInfo: {
      type: Object,
      required: true,
    },
    orderId: {
      required: true,
    },
    conversationList: {
      type: Array,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
  },

  components: {
    TwoColumn,
  },

  setup(props, { emit }) {
    const currentSearch = toRefs(props).search

    function updatePropSearch(val: any) {
      emit('update:search', val)
    }

    function onInput(val: any) {
      updatePropSearch(val)
      emit('on-input-search', 'desktop')
    }

    return {
      currentSearch,
      onInput,
      updatePropSearch,
    }
  },
})
