























































































import {
  ref,
  defineComponent,
} from 'vue-demi'

import { User } from '@/types/api'

// @ts-ignore
import StarRating from 'vue-star-rating'

import ChangeStatusDialog from '@/components/dialogs/ChangeStatusDialog.vue'

interface Props extends Record<string, any> {
  readonly user: User
}

export default defineComponent<Props>({
  components: {
    StarRating,
    ChangeStatusDialog,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const rating = ref(3)

    return { rating }
  },
})

